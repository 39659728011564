
import { Options, Vue } from "vue-class-component";
import axios from "@/plugins/axios";
import { ElMessage } from "element-plus";
import VerifyEmail from "./VerifyEmail.vue";

interface Result {
  email: "";
  message: "";
}
@Options({
  components: {
    VerifyEmail,
  },
})
export default class ModalNewPassword extends Vue {
  refForm = "newPasswordForm";
  isVerify = false;
  formSignIn = {};
  formResetPassword = {
    email: "",
    password: "",
    passwordConfirm: "",
    tokenResetPassword: "",
  };
  rules = {
    password: [
      {
        required: true,
        message: "Please input password",
        trigger: "blur",
      },
      {
        min: 8,
        max: 20,
        message: "password must be at least 8 characters",
        trigger: "blur",
      },
    ],
    passwordConfirm: [
      {
        required: true,
        message: "Please input password again",
        trigger: "blur",
      },
      {
        min: 8,
        max: 20,
        message: "password must be at least 8 characters",
        trigger: "blur",
      },
    ],
  };
  tokenResetPassword: any;
  async created() {
    this.tokenResetPassword = this.$route.query.token?.toString();
    this.checkTokenResetPassword();
  }
  async checkTokenResetPassword() {
    let dataForm = {
      tokenResetPassword: this.tokenResetPassword,
    };
    await this.$store.dispatch("setLoading", true, { root: true });
    let res: Result = await axios.post("/confirm-reset-password", dataForm);
    if (res) {
      this.formResetPassword.email = res.email;
    }
    if (res.message) {
      ElMessage.error(res.message);
    }
    await this.$store.dispatch("setLoading", false, { root: true });
  }
  async submitForm() {
    let regexPassword =
      /(?=^.{8,20}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/;
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement;
    const isValidate = await refFrorm.validate();
    if (!isValidate) return;
    if (regexPassword.exec(this.formResetPassword.password) == null) {
      return ElMessage.error(
        "Password 1 small-case letter, 1 Capital letter, 1 digit, 1 special character and the length should be between 8-20 characters !!!"
      );
    } else if (
      this.formResetPassword.password !== this.formResetPassword.passwordConfirm
    ) {
      return ElMessage.error("Confirm password is incorrect !!!");
    }
    this.formResetPassword["tokenResetPassword"] = this.tokenResetPassword;
    await this.$store.dispatch("setLoading", true, { root: true });
    let res: Result = await axios.post(
      "/reset-password",
      this.formResetPassword
    );
    if (res) {
      ElMessage.success(res.message);
    }
    setTimeout(() => {
      let formSignIn = {
        email: this.formResetPassword.email,
        password: this.formResetPassword.password,
      };
      this.signIn(formSignIn);
    }, 200);
    await this.$store.dispatch("setLoading", false, { root: true });
  }
  async signIn(formSignIn: any) {
    this.$emit("goSignIn", formSignIn);
  }
}


import { Options, Vue } from "vue-class-component";
import { ElMessage } from "element-plus";

@Options({
  components: {},
  props: {
    formDataSignIn: Object,
    formDataSignUp: Object,
  },
})
export default class VerifyEmail extends Vue {
  verifyHash = "";
  refForm = "VerifyEmail";
  timeLeft = 60;
  disableResend = true;
  newExpireVerifyHash = new Date();
  formDataSignIn: any;
  rules = {
    verifyHash: [
      {
        required: true,
        message: "Please enter the code",
        trigger: "blur",
      },
    ],
  };

  mounted() {
    this.countDown();
  }
  countDown() {
    let intervalId = setInterval(() => {
      this.timeLeft = this.timeLeft - 1;
      if (this.timeLeft == 0) {
        clearInterval(intervalId);
        this.disableResend = false;
      }
    }, 1000);
  }
  async submitForm(formData: any) {
    const refFrorm = this?.$refs[this.refForm] as HTMLFormElement;
    const isValidate = await refFrorm.validate();
    if (!isValidate) return;
    await this.$store.dispatch("setLoading", true, { root: true });
    // call api
    const siginInResponse = await this.$store.dispatch("auth/signIn", formData);
    if (siginInResponse.tokenUser && siginInResponse.fisrtLogin) {
      await this.$store.dispatch("setLoading", false, { root: true });
      return this.$emit("resetPassword", siginInResponse);
    }
    await this.$store.dispatch("setLoading", false, { root: true });
    if (!siginInResponse.tokenUser) {
      this.$message.warning(
        siginInResponse.message || JSON.stringify(siginInResponse)
      );
      return;
    }
    this.$message.success(siginInResponse.message);
    await this.$store.dispatch("auth/setTokenUser", siginInResponse.tokenUser);
    await this.$router.push({ name: "GetStartedPage" });
    location.reload();
  }
  async resendEmail(formData: any) {
    formData.verifyHash = "";
    await this.$store.dispatch("setLoading", true, { root: true });
    // call api
    const siginInResponse = await this.$store.dispatch("auth/signIn", formData);

    await this.$store.dispatch("setLoading", false, { root: true });
    if (siginInResponse.status) {
      await ElMessage.success("Send email successfully !!!");
      this.disableResend = true;
      this.newExpireVerifyHash = new Date(Date.now() + 15 * 60000);
      this.timeLeft = 30;
      this.countDown();
    } else {
      this.$message.warning(
        siginInResponse.message || JSON.stringify(siginInResponse)
      );
      return;
    }
  }
}
